import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, FormItem, Input, Link as LinkVk } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { VldBuilder, vlds } from 'validatorus-react';
import moment from 'moment';
export const LoginPage = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const [loginPageType, setLoginPageType] = React.useState(1);
    const [timer, setTimer] = React.useState('1:00');
    const inputEmail = new VldBuilder()
        .with(vlds.VEmail)
        .withFname('E-mail');
    const inputPsw = new VldBuilder()
        .with(vlds.VLen, 6, 64)
        .withFname('Password');
    const inputCode = new VldBuilder()
        .with(vlds.VLen, 6, 6)
        .withFname('Code');
    const history = useNavigate();
    function startTimer() {
        const eventTime = Number((Date.now() / 1000) + 60); // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
        const currentTime = Date.now() / 1000; // Timestamp - Sun, 21 Apr 2013 12:30:00 GMT
        const diffTime = eventTime - currentTime;
        let duration = moment.duration(diffTime * 1000, 'milliseconds');
        const interval = 1000;
        if (diffTime < 0)
            return;
        const interv = setInterval(() => {
            duration = moment.duration(Number(duration) - interval, 'milliseconds');
            if (duration.seconds() === 0)
                clearInterval(interv);
            setTimer(`${duration.minutes()}:${duration.seconds()}`);
        }, interval);
    }
    async function login() {
        const data = await props.loginUser(inputEmail.value, inputPsw.value);
        if (data) {
            // setLoginPageType(2)
            // inputCode.reset(true, true)
            // startTimer()
            props.setLoginPage(false);
            props.setAuth(2);
            history('/restourants');
        }
    }
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    return (_jsx("div", { className: "qrall-login", id: props.id, children: _jsxs("div", { className: "qrall-login-block black-block", style: { width: '100%' }, children: [_jsx("h5", { className: "qrall-h5", children: "Register & Login" }), loginPageType === 1
                    ? _jsxs("div", { className: "login-form", children: [_jsx(FormItem, { top: "E-mail", bottom: inputEmail.error, children: _jsx(Input, { type: "email", placeholder: 'email@examle.com', value: inputEmail.value, onChange: e => inputEmail.change(e.target.value), status: inputEmail.iserr, name: "email" }) }), _jsx(FormItem, { top: "Password", bottom: inputPsw.error, children: _jsx(Input, { type: "password", placeholder: '', value: inputPsw.value, onChange: e => inputPsw.change(e.target.value), status: inputPsw.iserr, name: "password" }) }), _jsx(FormItem, { children: _jsx(Button, { size: "l", stretched: true, onClick: () => login(), disabled: inputEmail.iserr !== 'valid' || inputPsw.iserr !== 'valid', children: "Login" }) })] })
                    : null, loginPageType === 2
                    ? _jsxs("div", { className: "login-form", children: [_jsx(FormItem, { top: "Code", bottom: timer === '0:0'
                                    ? _jsx(LinkVk, { onClick: () => {
                                            login();
                                            if (window.gtag)
                                                window.gtag('event', 'reg_click_resend-code');
                                        }, children: "Resend code" }) : `Resend code ${timer}`, children: _jsx(Input, { type: "text", placeholder: 'XXXXXX', value: inputCode.value, onChange: e => inputCode.change(e.target.value), status: inputCode.iserr }) }), _jsx(FormItem, { children: _jsx(Button, { size: "l", stretched: true, onClick: () => {
                                        // sendCode()
                                        if (window.gtag)
                                            window.gtag('event', 'reg_click_login');
                                    }, children: "Login" }) })] })
                    : null] }) }));
};

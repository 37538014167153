import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, CardGrid, Div, FormItem, FormLayoutGroup, Input, Panel } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export const Settings = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const history = useNavigate();
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    return (_jsx(Panel, { id: props.id, children: _jsxs(Div, { style: { paddingTop: 0 }, children: [_jsx("div", { className: "admin-header-block", children: _jsx("h5", { className: "qrall-h5", children: "Settings" }) }), _jsxs("div", { children: [_jsx("span", { children: "Comming soon..." }), _jsxs(FormLayoutGroup, { mode: "horizontal", children: [_jsx(FormItem, { top: "Name", children: _jsx(Input, { type: "text", placeholder: 'Your name', disabled: true }) }), _jsx(FormItem, { top: "E-mail", children: _jsx(Input, { type: "email", placeholder: 'email@example.com', disabled: true }) })] }), _jsx(FormItem, { children: _jsx("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'end' }, children: _jsx(Button, { size: "l", disabled: true, children: "Save settings" }) }) })] }), _jsx("div", { className: "admin-header-block", children: _jsx("h5", { className: "qrall-h5", children: "Pay plan" }) }), _jsx("div", { children: _jsxs(CardGrid, { size: "m", children: [_jsx(Card, { onClick: () => props.setActiveModal('qr2'), children: _jsxs(Div, { children: [_jsx("div", {}), _jsx("h2", { children: "1 month (first month free)" }), _jsx("div", { children: "50 \u20BE" })] }) }), _jsx(Card, { children: _jsxs(Div, { children: [_jsx("div", {}), _jsx("h2", { children: "1 year + starter pack" }), _jsx("div", { children: "500 \u20BE" })] }) })] }) })] }) }));
};

export class StoragePolus {
    constructor() {
        this._data = window.localStorage;
    }
    save(key, data) {
        try {
            this._data.setItem(`qr-${key}`, data);
        }
        catch (error) {
            console.error(error);
            return false;
        }
        return true;
    }
    get(key) {
        try {
            return this._data.getItem(`qr-${key}`);
        }
        catch (error) {
            console.error(error);
            return false;
        }
    }
    del(key) {
        try {
            this._data.removeItem(`qr-${key}`);
        }
        catch (error) {
            console.error(error);
            return false;
        }
        return true;
    }
}

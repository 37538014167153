import { StoragePolus } from './storage';
export class Translate {
    constructor() {
        console.log('start translate');
        this._storage = new StoragePolus();
    }
    changeLang(lang) {
        this._storage.save('lang', lang);
        return true;
    }
    getLang() {
        const l = this._storage.get('lang');
        return l ?? 'en';
    }
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Div, Group, Panel } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export const Error = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const history = useNavigate();
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
        if (props.text === '' || !props.text) {
            history('/merchants');
        }
    }, []);
    return (_jsx(Panel, { id: props.id, children: _jsx(Group, { children: _jsxs(Div, { children: [_jsx("div", { className: "admin-header-block", children: _jsx("h5", { className: "qrall-h5", style: { textAlign: 'center', width: '100%' }, children: "Error 500" }) }), _jsxs("div", { style: { textAlign: 'center', width: '100%' }, children: ["Reqest id ", _jsx("span", { style: { userSelect: 'all' }, children: props.text })] })] }) }) }));
};

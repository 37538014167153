import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-new */
/* eslint-disable @typescript-eslint/naming-convention */
import { Icon28ClockOutline, Icon28GlobeOutline, Icon28LocationMapOutline, Icon28LogoInstagram, Icon28MailOutline, Icon28PhoneOutline, Icon28RadiowavesAroundOutline, Icon28SendOutline } from '@vkontakte/icons';
import { Div, Group, Panel, Spinner, CardGrid, Card, SimpleCell, HorizontalScroll, Tabs, TabsItem, Avatar } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
const ipfs = 'https://cloudflare-ipfs.com/ipfs/';
export const MenuPage = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const [typePage, setTypePage] = React.useState(0);
    const [restourant, setRestourant] = React.useState(undefined);
    const [menu, setMenu] = React.useState(undefined);
    const [categoryList, setCategoryList] = React.useState(undefined);
    const history = useNavigate();
    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement({
            pageLanguage: 'en,ru,ka,uk,tr,hy',
            autoDisplay: false,
            includedLanguages: 'en,ka,uk,ru,hy,tr'
        }, 'google_translate_element');
    };
    const { idMerchant } = useParams();
    async function getMerchant() {
        // console.log('get', props.api.user._client)
        const resp = await props.newApi.getOneRestourantPublic(Number(idMerchant));
        if (!props.onErrorApi(resp))
            return false;
        const respType = resp.restourant;
        if (!respType)
            return false;
        setRestourant(respType);
        setTypePage(0);
        console.log(respType);
        props.setSelectMerhant(respType);
        // changeFormData(resp as RestourantApiResp)
        return true;
    }
    async function getMenu() {
        const resp = await props.newApi.getMenuPublic(Number(idMerchant));
        if (!props.onErrorApi(resp))
            return false;
        const respType = resp.menu;
        setMenu(respType);
        return true;
    }
    async function getCategory() {
        const resp = await props.newApi.getCategoryPublic(Number(idMerchant));
        if (!props.onErrorApi(resp))
            return false;
        console.log(resp);
        const respType = resp;
        setCategoryList(respType.categorys);
        // setActiveModal(null)
        return true;
    }
    useEffect(() => {
        if (idMerchant && !firstRender) {
            setFirstRender(true);
            getMerchant();
            // props.getUser()
            getCategory();
            getMenu();
            const addScript = document.createElement('script');
            addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
            document.body.appendChild(addScript);
            window.googleTranslateElementInit = googleTranslateElementInit;
        }
    }, [props.newApi]);
    return (_jsxs(Panel, { id: props.id, children: [_jsx(Div, { style: { paddingTop: 0, boxSizing: 'border-box' }, className: 'mobile-header', children: _jsxs("div", { className: "admin-header-block2", style: { marginBottom: '0' }, children: [_jsx(Avatar, { src: ipfs + restourant?.logo, size: 72, mode: "app", style: { marginRight: '16px' } }), _jsxs("div", { style: {
                                display: 'flex',
                                alignItems: 'start',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                marginRight: '16px'
                            }, children: [_jsx("h5", { className: "qrall-h5", style: { marginLeft: '0' }, children: restourant?.full_name }), _jsx("span", { children: restourant?.full_desc })] }), _jsx("div", { id: "google_translate_element" })] }) }), _jsxs("div", { style: {
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'center',
                    width: '100%',
                    flexDirection: props.isDesktop ? 'initial' : 'column'
                }, children: [restourant
                        ? _jsxs("div", { style: { width: props.isDesktop ? 'calc(100% - 300px)' : '100%', marginRight: '16px' }, children: [_jsx("div", { style: { margin: '0 0 16px 0' }, children: categoryList
                                        ? _jsx(Tabs, { mode: "accent", children: _jsxs(HorizontalScroll, { arrowSize: "l", children: [_jsx(TabsItem, { selected: typePage === 0, onClick: () => setTypePage(0), children: "All" }), categoryList.map((c, key) => (_jsx(TabsItem, { selected: typePage === c.id, onClick: () => setTypePage(c.id), children: c.full_name }, key)))] }) }) : null }), _jsx(CardGrid, { size: props.isDesktop ? 's' : 'm', children: menu && menu.filter(m => m.id_categorys === typePage || typePage === 0).map((m, key) => (_jsxs(Card, { style: { overflow: 'hidden' }, className: "card-menu", onClick: () => {
                                            props.setOneMenu(m);
                                            props.setActiveModal('one_menu');
                                        }, children: [_jsx("div", { style: { backgroundImage: `url(${ipfs + m.img})` }, className: 'img-card' }), _jsxs(Div, { children: [_jsx("h4", { style: { marginTop: 0 }, children: m.full_name }), _jsxs("div", { style: { textAlign: 'right' }, children: [m.price, " \u20BE"] })] })] }, key))) })] })
                        : _jsx("div", { style: { display: 'flex', alignItems: 'center', flexDirection: 'column' }, children: _jsx(Spinner, { size: "large", style: { margin: '20px 0' } }) }), restourant
                        ? _jsx(Group, { style: { width: props.isDesktop ? '300px' : 'calc(100% - 40px)' }, children: _jsxs(Div, { children: [_jsx("h3", { children: restourant.full_name }), restourant.address ? _jsx(SimpleCell, { after: restourant.address, before: _jsx(Icon28LocationMapOutline, {}), style: { overflow: 'hidden' }, children: "Address" }) : null, restourant.phone ? _jsx(SimpleCell, { after: restourant.phone, before: _jsx(Icon28PhoneOutline, {}), children: "Phone" }) : null, restourant.email ? _jsx(SimpleCell, { after: restourant.email, before: _jsx(Icon28MailOutline, {}), children: "Email" }) : null, restourant.site ? _jsx(SimpleCell, { after: restourant.site, before: _jsx(Icon28GlobeOutline, {}), children: "Site" }) : null, restourant.time_work ? _jsx(SimpleCell, { after: restourant.time_work, before: _jsx(Icon28ClockOutline, {}), children: "Time work" }) : null, restourant.wifi ? _jsx(SimpleCell, { after: restourant.wifi, before: _jsx(Icon28RadiowavesAroundOutline, {}), children: "WiFi" }) : null, restourant.instagram ? _jsx(SimpleCell, { after: restourant.instagram, before: _jsx(Icon28LogoInstagram, {}), children: "Instagram" }) : null, restourant.telegram ? _jsx(SimpleCell, { after: restourant.telegram, before: _jsx(Icon28SendOutline, {}), children: "Telegram" }) : null] }) }) : null] })] }));
};

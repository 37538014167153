import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon28DoorArrowRightOutline } from '@vkontakte/icons';
import { Avatar, Button, Card, CardGrid, CustomSelectOption, PanelHeader, Select, SimpleCell } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { LANGS } from '../logic/const';
import { Translate } from '../logic/lang';
import logo from '../img/logo.svg';
export const HeaderBlock = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const { t, i18n } = useTranslation('common');
    const location = useLocation();
    const history = useNavigate();
    function changeLang(short) {
        // if (!props.trans) return undefined
        // console.log('change', numb)
        // props.trans.change(props.trans.langs[Number(numb)])
        console.log('i18n', i18n);
        i18n.changeLanguage(short);
        const tr = new Translate();
        tr.changeLang(short);
        return true;
    }
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    useEffect(() => {
    }, []);
    return (_jsx(PanelHeader, { separator: false, className: "qrall-ge-header-full qrall-ge-header header-full", before: _jsx("img", { src: logo, className: "logo", onClick: () => history('/restourants') }), after: _jsxs("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'end' }, children: [LANGS ? _jsx(Select, { className: "lang-inp", placeholder: i18n.language, options: LANGS.map((user, key) => ({
                        label: user.name,
                        value: user.short,
                        avatar: user.logo
                    })), value: LANGS.find((l) => l.short === i18n.language)?.short, onChange: (e) => changeLang(e.target.value), renderOption: ({ option, ...restProps }) => (_createElement(CustomSelectOption, { ...restProps, key: option.value, before: _jsx(Avatar, { size: 24, src: option.avatar, mode: "app" }) })) }) : null, _jsx(CardGrid, { size: 'l', children: _jsx(Card, { children: _jsx(SimpleCell, { onClick: () => props.logOut(), after: _jsx(Icon28DoorArrowRightOutline, {}), children: props.userInfo?.user.email }) }) })] }), children: _jsxs("div", { className: "qrall-ge-header-btn", style: { display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: '10px' }, children: [_jsx(Button, { mode: "tertiary", size: "l", onClick: () => history('/restourants'), children: t('restourants') }), _jsx(Button, { mode: "tertiary", size: "l", onClick: () => history('/settings'), children: t('settings') }), _jsx(Button, { mode: "tertiary", size: "l", onClick: () => history('/restourants'), children: t('pay_plan') })] }) }));
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Button, Div, Panel, Spinner } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IPFS } from '../../logic/const';
export const Main = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const [restourantsList, setRestourantsList] = React.useState(undefined);
    const { t, i18n } = useTranslation('common');
    const history = useNavigate();
    async function getMerchants() {
        const resp = await props.newApi.getAllRestourant();
        if (!props.onErrorApi(resp))
            return false;
        const respType = resp;
        console.log('respType.restourants', respType);
        setRestourantsList(respType.restourants);
        return true;
    }
    useEffect(() => {
        if (!firstRender && props.newApi.au) {
            setFirstRender(true);
            // getMerchants()
            // props.getUser()
            props.getUser();
            getMerchants();
        }
    }, [props.newApi]);
    return (_jsx(Panel, { id: props.id, children: _jsxs(Div, { style: { paddingTop: 0 }, children: [_jsxs("div", { className: "admin-header-block", children: [_jsx("h5", { className: "qrall-h5", children: t('restourants') }), _jsx(Button, { size: "l", onClick: () => props.setActiveModal('create_merchant'), children: t('create_restourant') })] }), restourantsList
                    ? _jsx("div", { className: "mobile-table", children: restourantsList.length > 0
                            ? _jsxs("table", { className: "qrall-table qrall-table-big", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "ID" }), _jsx("th", { children: t('logo') }), _jsx("th", { children: t('name') }), props.isDesktop ? _jsx("th", { children: t('address') }) : null] }) }), _jsx("tbody", { children: restourantsList.map((restourant, key) => (_jsxs("tr", { onClick: () => history(`/restourant/${restourant.id}`), children: [_jsx("td", { children: restourant.id }), _jsx("td", { children: _jsx(Avatar, { src: IPFS + restourant.logo, size: 52, mode: "app" }) }), _jsx("td", { children: restourant.full_name }), _jsx("td", { children: restourant.address })] }, key))) })] })
                            : _jsx("div", { style: { marginTop: '16px', textAlign: 'center' }, children: t('dont_have_restourants') }) })
                    : _jsx("div", { style: { display: 'flex', alignItems: 'center', flexDirection: 'column' }, children: _jsx(Spinner, { size: "large", style: { margin: '20px 0' } }) })] }) }));
};

import logoEng from '../img/un.svg';
import logoGeo from '../img/ge.svg';
import logoUkr from '../img/uk.svg';
export const IPFS = 'https://cloudflare-ipfs.com/ipfs/';
export const LANGS = [
    {
        name: 'English',
        short: 'en',
        logo: logoEng
    },
    {
        name: 'Georgian',
        short: 'ge',
        logo: logoGeo
    },
    {
        name: 'Українська',
        short: 'uk',
        logo: logoUkr
    }
];
export const texts = {
    Restourants: ['Restourants', 'რესტორნები', 'Ресторани'],
    Settings: ['Settings', 'პარამეტრები', 'Налаштування'],
    Pay_plan: ['Pay plan', 'გადახდის გეგმა', 'План оплати']
};

import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { WebviewType, AdaptivityProvider, ConfigProvider, IOS } from '@vkontakte/vkui';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { App } from './App';
import en from './translate/en/menu.json';
import ge from './translate/ge/menu.json';
import uk from './translate/uk/menu.json';
const el = document.createElement('div');
document.body.appendChild(el);
// eruda.init({
//     container: el,
//     tool: [ 'console', 'elements' ]
// })
const ConfigProviderFix = ConfigProvider;
const AdaptivityProviderFix = AdaptivityProvider;
Sentry.init({
    dsn: 'https://8e91c923d1064a138b64dc193b38f015@o4505108760035328.ingest.sentry.io/4505427930447872',
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/]
        }),
        new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',
    resources: {
        en: { common: en },
        ge: { common: ge },
        uk: { common: uk }
    }
});
ReactDOM.render(_jsx(BrowserRouter, { basename: '/', children: _jsx(React.StrictMode, { children: _jsx(I18nextProvider, { i18n: i18next, children: _jsx(ConfigProviderFix, { appearance: 'dark', webviewType: WebviewType.INTERNAL, platform: IOS, children: _jsx(AdaptivityProviderFix, { children: _jsx(App, {}) }) }) }) }) }), document.querySelector('#root'));

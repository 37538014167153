import axios from 'axios';
import { create } from 'ipfs-http-client';
import { StoragePolus } from './storage';
export class NewApi {
    constructor(token) {
        // private _url = 'http://192.168.50.120:3000/'
        this._url = 'https://api.qrall.ge/';
        this._au = false;
        this._token = token;
        this._storage = new StoragePolus();
        this.checkAuth();
    }
    get au() {
        return this._au;
    }
    checkAuth() {
        if (this._storage) {
            const t = this._storage.get('token');
            const t2 = this._storage.get('refresh');
            const t3 = this._storage.get('user');
            if (!t || !t2 || !t3) {
                this._au = false;
                return false;
            }
            this._token = t;
            this._tokenRefresh = t2;
            this._user_id = t3;
            this._au = true;
            return true;
        }
        return false;
    }
    logOut() {
        console.log('this._storage', this);
        if (this._storage) {
            this._storage.del('token');
            this._storage.del('refresh');
            this._storage.del('user');
            return true;
        }
        return false;
    }
    async send(type, path, params) {
        try {
            const par = params;
            if (type !== 'public') {
                par.token = this._token;
            }
            const resp = await axios({
                url: `${this._url}${path}`,
                method: 'post',
                // headers: type !== 'public' ? { Authorization: `Bearer ${this._token}` } : {},
                data: par
            });
            if (resp.data.code && resp.data.code !== 200) {
                return undefined;
            }
            const res = resp.data;
            // const resFull: AllApiResp = {
            //     data: res,
            //     code: 200
            // }
            return res;
        }
        catch (err) {
            console.error('err axios', err);
            return undefined;
        }
    }
    async sendEmailCode(email, psw) {
        const res = await this.send('public', 'user.login', { email, psw });
        if (!res) {
            return undefined;
        }
        const respType = res.data;
        console.log('token', respType.token);
        this._token = respType.token;
        this._tokenRefresh = '1';
        this._storage.save('token', this._token);
        this._storage.save('refresh', this._tokenRefresh);
        this._storage.save('user', '1');
        this._au = true;
        return respType;
    }
    async getAllRestourant() {
        const res = await this.send('private', 'restourant.all', {});
        if (!res) {
            return undefined;
        }
        // if (res.code !== 200) {
        //     return <ErrorApiResp>(<AllApiResp>res).data
        // }
        return res.data;
    }
    async getOneRestourantPublic(id) {
        const res = await this.send('public', 'public.restourant.info', { id });
        if (!res) {
            return undefined;
        }
        // if (res.code !== 200) {
        //     return <ErrorApiResp>(<AllApiResp>res).data
        // }
        return res.data;
    }
    async getMenuPublic(id_restourants) {
        const res = await this.send('public', 'public.menu.info', { id_restourants });
        if (!res) {
            return undefined;
        }
        return res.data;
    }
    async getCategoryPublic(id_restourants) {
        const res = await this.send('public', 'public.menu.getCategory', { id_restourants });
        if (!res) {
            return undefined;
        }
        return res.data;
    }
    async getMenu(id_restourants) {
        const res = await this.send('private', 'menu.info', { id_restourants });
        if (!res) {
            return undefined;
        }
        return res.data;
    }
    async getCategory(id_restourants) {
        const res = await this.send('private', 'menu.getCategory', { id_restourants });
        if (!res) {
            return undefined;
        }
        return res.data;
    }
    async getOneRestourant(id) {
        const res = await this.send('private', 'restourant.info', { id });
        if (!res) {
            return undefined;
        }
        // if (res.code !== 200) {
        //     return <ErrorApiResp>(<AllApiResp>res).data
        // }
        return res.data;
    }
    async createRestourant(full_name, full_desc, phone, address, time_work, wifi, email, site, instagram, telegram, type_res, logo) {
        const res = await this.send('private', 'restourant.create', {
            full_name,
            full_desc,
            phone,
            address,
            time_work,
            wifi,
            email,
            site,
            instagram,
            telegram,
            type_res,
            logo
        });
        if (!res) {
            return undefined;
        }
        // if (res.code !== 200) {
        //     return <ErrorApiResp>(<AllApiResp>res).data
        // }
        return res;
    }
    async createCategory(id_restourants, full_name) {
        const res = await this.send('private', 'menu.addCategory', { id_restourants, full_name });
        if (!res) {
            return undefined;
        }
        return res.data;
    }
    async createPosition(id_restourants, id_categorys, full_name, full_desc, price, img) {
        const res = await this.send('private', 'menu.addPosition', {
            id_restourants,
            full_name,
            id_categorys,
            full_desc,
            price,
            img
        });
        if (!res) {
            return undefined;
        }
        return res.data;
    }
    async updatePosition(id, id_categorys, full_name, full_desc, price, img) {
        const res = await this.send('private', 'menu.updatePosition', {
            id,
            full_name,
            id_categorys,
            full_desc,
            price,
            img
        });
        if (!res) {
            return undefined;
        }
        return res.data;
    }
    async updateRestourant(full_name, full_desc, phone, address, time_work, wifi, email, site, instagram, telegram, type_res, logo, id) {
        const res = await this.send('private', 'restourant.update', {
            full_name,
            full_desc,
            phone,
            address,
            time_work,
            wifi,
            email,
            site,
            instagram,
            telegram,
            type_res,
            logo,
            id
        });
        if (!res) {
            return undefined;
        }
        // if (res.code !== 200) {
        //     return <ErrorApiResp>(<AllApiResp>res).data
        // }
        return res;
    }
    async getMe() {
        const res = await this.send('private', 'user.info', {});
        if (!res) {
            return undefined;
        }
        // if (res.code !== 200) {
        //     return <ErrorApiResp>(<AllApiResp>res).data
        // }
        return res.data;
    }
    async infuraUploadImg(file) {
        const projectId = '2PhM1nD1iRxa28QNXXE1ZOpC4Nr';
        const projectSecret = 'a3e0e635349b318dd6827d9e7bfd48e2';
        const auth = `Basic ${Buffer.from(`${projectId}:${projectSecret}`).toString('base64')}`;
        const client = create({
            host: 'ipfs.infura.io',
            port: 5001,
            protocol: 'https',
            headers: { authorization: auth }
        });
        try {
            const added = await client.add(file);
            console.log(added);
            const hex = Buffer.from(added.cid['/']).toString('hex');
            const url = `https://cloudflare-ipfs.com/ipfs/${added.path}`;
            console.log('IPFS URI: ', url);
            return added.path;
        }
        catch (error) {
            console.log('Error uploading file: ', error);
            return undefined;
        }
    }
}
